<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备类型">
          <el-select v-model="searchForm.typeId" placeholder="请选择设备类型" size="small" clearable>
            <el-option v-for="(item,i) in typeList" :key="i" :label="item.typeName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备特征码">
          <el-input v-model="searchForm.equipmentCode" placeholder="请输入设备特征码" size="small"></el-input>
        </el-form-item>
        <el-form-item label="时间范围">
          <el-date-picker size="small" v-model="searchForm.date" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="warningList" stripe style="width: 100%" height="100%">
        <el-table-column prop="equipmentTypeName" label="设备类型" show-overflow-tooltip width="120">
        </el-table-column>
        <el-table-column prop="unitName" label="所属单位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="content" label="内容" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="alarmTypeName" label="告警类型" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="alarmDate" label="告警时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.alarmDate|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="errorReason" label="告警原因" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip width="100" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" size="normal" v-show="scope.row.status===0">发生</el-tag>
            <el-tag type="primary" size="normal" v-show="scope.row.status===1">已派警</el-tag>
            <el-tag type="success" size="normal" v-show="scope.row.status===2">已出警</el-tag>
            <el-tag type="info" size="normal" v-show="scope.row.status===-1">已解除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      warningList: [],
      typeList: [],
      searchForm: {
        date: []
      }
    }
  },
  created () {
    this.initTable()
    this.getTypeList()
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.searchForm.typeId) {
        data.typeId = this.searchForm.typeId
      }
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      if (this.searchForm.date) {
        data.statTime = this.searchForm.date[0]
      }
      if (this.searchForm.date) {
        data.endTime = this.searchForm.date[1]
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/alarm/listAllAlarm', { params: data })
      if (result.code === 200) {
        this.warningList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 查找行政区域
    async getTypeList () {
      const { data: result } = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      } else {
        this.$message.error(result.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
</style>
